import dayjs from 'dayjs';
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

// Tidy URLs
export function tidyUrl(url) {
	return url ? url.replace('http://', '').replace('https://', '').replace(/\/$/g, '') : '';
}

// Tidy dates
export function tidyDate(date) {
	return date ? dayjs(date).format('MM/DD/YYYY') : '';
}

export function tidyTime(date) {
	return date ? dayjs(date).format('h:mm A') : '';
}

export function tidyDateTime(date) {
	return date ? dayjs(date).format('MM/DD/YYYY @ h:mma') : '';
}

export function tidyRelativeDate(date) {
	return date ? dayjs().to(dayjs(date)) : '';
}

export function tidyDateWords(date) {
	return date ? dayjs(date).format('D MMMM YYYY') : '';
}

// Tidy text
export function tidyCapitalize(text) {
	return text
		.toLowerCase()
		.split(/ |_/)
		.map(word => word.charAt(0).toUpperCase() + word.substring(1))
		.join(' ');
}

export function tidyUnderscores(text) {
	return text
		.toLowerCase()
		.split('_')
		.map(word => word.charAt(0).toUpperCase() + word.substring(1))
		.join(' ');
}

// Tidy database strings
export function tidyDatabase(url) {
	const urlObject = new URL(url);
	if (urlObject.hostname === 'api.appfoliotest.com') {
		const db = urlObject.pathname.replace('/api/v1/listings/', '');
		return `${db}.appfolio.com`;
	} else {
		return urlObject.hostname;
	}
}

export function tidyPropertyGroup(url) {
	return new URL(url).searchParams.get('property_lists[]');
}
