export default function Error() {
	return (
		<div className='p-3 p-md-5 d-flex flex-wrap justify-content-center align-items-center text-danger'>
			<div className='text-center'>
				<h1 style={{ fontSize: '5rem' }}>
					<i className='fas fa-exclamation-circle me-2'></i> Error
				</h1>
				<p className='text-muted'>Sorry, looks like you don't have permission to access this page.</p>
			</div>
		</div>
	);
}
