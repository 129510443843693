import styled from 'styled-components';

export const Table = styled.div`
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	border-radius: 0.5rem;
	background: white;
	overflow-x: auto;
`;

export const Cell = styled.div`
	padding: 1rem;
	overflow: hidden;
	background: white;
`;

export const Row = styled.div`
	display: grid;
	position: relative;
	transition: 0.1s;

	&:not(:last-of-type) {
		${Cell} {
			border-bottom: 1px solid #eee;
		}
	}
`;

export const Header = styled(Row)`
	${Cell} {
		background: var(--bs-dark);
		color: white;
		font-weight: bold;
	}
`;

export const OverflowCell = styled(Cell)`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;
