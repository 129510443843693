import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Form } from 'react-bootstrap';
import styled from 'styled-components';
import UserTile from '../Log/UserTile';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import PageHeader from 'components/shared/PageHeader';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import useSWR from 'swr';

// Component style
const Users = styled.div`
	display: grid;
	gap: 1rem;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;

const UsersAdmin = () => {
	// State
	const [showWebsitesTeam, setShowWebsitesTeam] = useState(false);

	// Hooks
	const navigate = useNavigate();
	let { data: users } = useSWR('/api/v1/users/all');

	if (showWebsitesTeam) {
		users = users.filter(user => user.isWebsiteTeam);
	}

	// Variables
	const usersExist = users && users.length > 0;
	const userNames = usersExist ? users.map(user => user.name) : [];

	// Go to user
	const goToEditUserPage = e => {
		const selected = e[0];
		const { googleId } = users.filter(user => user.name === selected)[0];
		navigate(`/users/admin/edit/${googleId}`);
	};

	return (
		<div>
			<PageHeader heading={'Users Admin'} subheading={'Update user permissions per app.'} />
			<Row className='mb-4'>
				<Col xl={8}>
					<Form>
						<Form.Group>
							<Typeahead className='border-0 shadow-sm mb-2' id='basic-typeahead-single' labelKey='name' options={userNames} onChange={e => goToEditUserPage(e)} placeholder='Enter a name or email...' minLength={2} />
						</Form.Group>
						<Form.Group className='mt-3'>
							<Form.Check type='switch' id='websites-team' label='Display Websites Team members only' checked={showWebsitesTeam} onChange={() => setShowWebsitesTeam(!showWebsitesTeam)} />
						</Form.Group>
					</Form>
				</Col>
			</Row>
			{usersExist ? (
				<>
					<h5 className='mb-3'>All Users</h5>
					<Users>
						{users.map(user => (
							<UserTile key={user.googleId} user={user} />
						))}
					</Users>
				</>
			) : (
				<LoadingOverlay />
			)}
		</div>
	);
};

export default UsersAdmin;
