import { useState } from 'react';
import { Form } from 'react-bootstrap';
import PageHeader from 'components/shared/PageHeader';
import styled from 'styled-components';
import useSWR from 'swr';
import LogTable from './LogTable';
import LoadingOverlay from 'components/shared/LoadingOverlay';

const Component = styled.div`
	display: grid;
	gap: 2rem;
`;

const Settings = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 1rem;

	@media (max-width: 576px) {
		grid-template-columns: 100%;
		gap: 0;
	}
`;

const Log = () => {
	// State
	const [appName, setAppName] = useState('');
	const [user, setUser] = useState('');
	const [dateRange, setDateRange] = useState('1');

	// Hooks
	const { data: apps } = useSWR('/api/v1/apps/all');
	const { data: log } = useSWR(`/api/v1/signins/log?date_range=${dateRange}&app_name=${appName}&user=${user}`);
	const { data: userList } = useSWR('/api/v1/signins/users');

	return (
		<>
			<PageHeader heading={'Users Log'} subheading={'Log of most recent sign-ins across all apps.'} />
			<Component>
				<Settings>
					<Form.Group controlId='appName'>
						<Form.Label>App name</Form.Label>
						<Form.Select value={appName} onChange={e => setAppName(e.target.value)}>
							<option value=''>All apps</option>
							{apps?.map(app => (
								<option key={app.id} value={app.id}>
									{app.name}
								</option>
							))}
						</Form.Select>
					</Form.Group>
					<Form.Group controlId='user'>
						<Form.Label>User</Form.Label>
						<Form.Select value={user} onChange={e => setUser(e.target.value)}>
							<option value=''>All users</option>
							{userList?.map(user => (
								<option key={user.email} value={user.email}>
									{user.email}
								</option>
							))}
						</Form.Select>
					</Form.Group>
					<Form.Group controlId='dateRange'>
						<Form.Label>Date range</Form.Label>
						<Form.Select value={dateRange} onChange={e => setDateRange(e.target.value)}>
							<option value='1'>Today</option>
							<option value='7'>Last 7 days</option>
							<option value='14'>Last 14 days</option>
							<option value='21'>Last 21 days</option>
							<option value='28'>Last 28 days</option>
						</Form.Select>
					</Form.Group>
				</Settings>
				{log && userList ? <LogTable data={log} users={userList} /> : <LoadingOverlay />}
			</Component>
		</>
	);
};

export default Log;
