import UsersLog from 'pages/Users/Log/Log';
import UsersAdmin from 'pages/Users/Admin/Admin';
import UsersEdit from 'pages/Users/Admin/Edit';
import Error from 'pages/Error';
import NotFound from 'pages/NotFound';
import Footer from 'components/layout/Footer';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import MessageBar from 'components/shared/MessageBar';
import { useLogin } from 'utils/UserContext';

const Component = styled.main`
	grid-area: main;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	overflow-x: hidden;
`;

const Wrapper = styled.div`
	max-width: 100%;
	width: 1280px;
	margin: 0 auto;
	padding: 2rem;

	@media (max-width: 767px) {
		padding: 1rem;
	}
`;

export default function Main() {
	// Hooks
	const { user } = useLogin();
	const { isAdmin } = { ...user };

	return (
		<Component>
			<MessageBar />
			<Wrapper>
				<Routes>
					<Route index element={<Navigate to='users/log' />} />
					<Route path='users'>
						<Route path='log' element={<UsersLog />} />
						<Route path='admin' element={isAdmin ? <Outlet /> : <Navigate to='/' />}>
							<Route index element={<UsersAdmin />} />
							<Route path='edit'>
								<Route path=':id' element={<UsersEdit />} />
							</Route>
						</Route>
					</Route>
					<Route path='error' element={<Error />} />
					<Route path='*' element={<NotFound />} />
				</Routes>
			</Wrapper>
			<Footer />
		</Component>
	);
}
