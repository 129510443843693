import { AdminBadge, WebsiteBadge } from 'components/shared/Badges';
import ProfileImage from 'components/shared/ProfileImage';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Component = styled(Link)`
	display: flex;
	gap: 1rem;
	grid-template-columns: repeat(3, max-content);
	align-items: center;
	color: var(--bs-dark) !important;
	text-decoration: none !important;
	align-items: center;

	padding: 1rem;
	background: white;
	border-radius: 0.5rem;
	transition: 0.1s ease;
	position: relative;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	overflow: hidden;
	max-width: 40rem;

	&:hover,
	&:focus-visible {
		z-index: 1;
		transform: translateY(-0.25rem);
		box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%);
	}
`;

const Image = styled.div`
	position: relative;
`;

const UserTile = ({ user }) => {
	// Destructure props
	const { email, googleId, permissions, name, imageUrl, isWebsiteTeam } = user;
	const superAdmin = permissions.filter(app => app.id === 'super')[0]?.isAdmin;

	return (
		<Component key={email} to={`/users/admin/edit/${googleId}`}>
			<Image>
				{superAdmin && <AdminBadge />}
				{isWebsiteTeam && <WebsiteBadge />}
				<ProfileImage src={imageUrl} alt={name} width='50' height='50' />
			</Image>
			<div className='overflow-hidden'>
				<h6 className='mb-1'>{name}</h6>
				<p className='m-0 text-truncate text-dark'>{email}</p>
				<small className='text-muted'>{googleId}</small>
			</div>
		</Component>
	);
};

export default UserTile;
