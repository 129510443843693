import styled from 'styled-components';
import { Table, Header, Cell } from 'components/shared/Table';
import { tidyDate, tidyTime } from 'utils/Tidy';
import Profile from './Profile';
import { Link } from 'react-router-dom';

const HeaderRow = styled(Header)`
	grid-template-columns: minmax(20rem, 2fr) minmax(10rem, 1fr) minmax(10rem, 1fr);
`;

const TableRow = styled(Link)`
	display: grid;
	grid-template-columns: minmax(20rem, 2fr) minmax(10rem, 1fr) minmax(10rem, 1fr);
	align-items: center;
	text-decoration: none !important;
	color: initial !important;
	transition: 0.2s;
	position: relative;
	background: white;

	&:hover,
	&:focus-visible {
		z-index: 1;
		box-shadow: rgb(0 0 0 / 18%) 0px 0 3rem;
	}

	&:not(:last-of-type) {
		border-bottom: 1px solid #eee;
	}
`;

const NoData = styled.div`
	padding: 1rem;
	text-align: center;
`;

const LogTable = ({ data, users }) => {
	// Props
	const headers = ['User', 'App', 'Timestamp'];

	return (
		<Table>
			<HeaderRow>
				{headers.map(header => (
					<Cell key={header}>{header}</Cell>
				))}
			</HeaderRow>
			{data.length > 0 ? (
				data.map((entry, i) => (
					<TableRow key={i} to={`/users/admin/edit/${entry._id}`}>
						<Profile entry={entry} users={users} />
						<Cell>{entry.app_name}</Cell>
						<Cell>
							<div>{tidyDate(entry.date)}</div>
							<small className='text-muted'>{tidyTime(entry.date)}</small>
						</Cell>
					</TableRow>
				))
			) : (
				<NoData>No data to display.</NoData>
			)}
		</Table>
	);
};

export default LogTable;
