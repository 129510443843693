import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import PermissionRow from './PermissionRow';
import PageHeader from 'components/shared/PageHeader';
import { useParams } from 'react-router-dom';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import useSWR from 'swr';
import styled from 'styled-components';
import { useMessage } from 'utils/MessageContext';
import { fetcher } from 'utils/Fetch';

import ProfileImage from 'components/shared/ProfileImage';

const Panel = styled.div`
	max-width: 40rem;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
`;

const Header = styled.h5`
	background: var(--bs-dark);
	color: white;
	margin: 0;
	padding: 1rem;
	border-radius: 0.5rem 0.5rem 0 0;
`;

const Content = styled.div`
	background: white;
	border-radius: 0 0 0.5rem 0.5rem;
	padding: 1.5rem;
`;

const UserRow = styled.div`
	display: flex;
	align-items: center;
	gap: 2rem;
	margin-bottom: 1.5rem;
`;

const UserName = styled.h4`
	margin: 0;
`;

const Details = styled.div`
	display: grid;
	gap: 0.25rem;
`;

const Buttons = styled.div`
	margin-top: 1.5rem;
	display: flex;
	gap: 0.75rem;
`;

const Edit = () => {
	// Hooks
	const { id } = useParams();
	const { data: user } = useSWR(`/api/v1/users/${id}`);
	const { setMessage } = useMessage();

	// State
	const [newPermissions, setNewPermissions] = useState([]);
	const [newIsWebsiteTeam, setNewIsWebsiteTeam] = useState(false);

	// Effects
	useEffect(() => {
		if (user) {
			setNewPermissions(user.permissions);
			setNewIsWebsiteTeam(user.isWebsiteTeam);
		}
	}, [user]);

	// Destructure user
	const { email, name, googleId, imageUrl } = { ...user };

	// Sort permissions
	const sortPermissions = (a, b) => (a.name < b.name ? -1 : 1);

	// Save changes to db
	const saveChanges = async () => {
		const body = JSON.stringify({ ...user, isWebsiteTeam: newIsWebsiteTeam, permissions: newPermissions });
		try {
			await fetcher(`/api/v1/users/${googleId}`, { method: 'PUT', body });
			setMessage({
				variant: 'success',
				text: `Successfully updated user permissions for ${name}.`
			});
		} catch (error) {
			setMessage({
				variant: 'danger',
				text: `There was an error updating user permissions for ${name}.`
			});
		}
	};

	// Handlers
	const handleWebsiteTeamToggle = e => {
		setNewIsWebsiteTeam(e.target.checked);
	};

	return (
		<>
			<PageHeader heading={'Edit User Permissions'} subheading={`View & update individual app user permissions.`} />
			{user ? (
				<>
					<Panel>
						<Header>User Details</Header>
						<Content>
							<UserRow>
								<ProfileImage src={imageUrl} alt={email} width='100' height='100' />
								<Details>
									<UserName>{name}</UserName>
									<a href={`mailto:${email}`} target='_blank' rel='noopener noreferrer'>
										{email}
									</a>
									<small className='text-muted'>{googleId}</small>
									<Form.Check type='checkbox' label='Website Team' id='website-team' name='Website Team' checked={newIsWebsiteTeam} onChange={e => handleWebsiteTeamToggle(e)} />
								</Details>
							</UserRow>
							{newPermissions.sort(sortPermissions).map(app => (
								<PermissionRow key={app.id} app={app} permissions={newPermissions} setPermissions={setNewPermissions} />
							))}
						</Content>
					</Panel>
					<Buttons>
						<Button onClick={() => saveChanges()}>
							Save <i className='fas fa-save'></i>
						</Button>
						<Button variant='secondary' as={Link} to={'/users/admin'}>
							Cancel <i className='fas fa-times-circle'></i>
						</Button>
					</Buttons>
				</>
			) : (
				<LoadingOverlay />
			)}
		</>
	);
};

export default Edit;
