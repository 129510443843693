import { AdminBadge, WebsiteBadge } from 'components/shared/Badges';
import ProfileImage from 'components/shared/ProfileImage';
import styled from 'styled-components';

import { tidyRelativeDate } from 'utils/Tidy';

const Component = styled.div`
	padding: 1rem;
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 1.5rem;
	align-items: center;
`;

const ProfileWrapper = styled.div`
	position: relative;
`;

const Date = styled.div`
	font-size: 0.9rem;
	color: var(--bs-secondary);
`;

const Profile = ({ entry, users }) => {
	// State
	const { imageUrl, name, permissions, isWebsiteTeam } = users.find(user => user._id === entry._id);
	const isAdmin = permissions.find(permission => permission.name === entry.app_name)?.isAdmin;

	return (
		<Component>
			<ProfileWrapper>
				{isWebsiteTeam && <WebsiteBadge />}
				<ProfileImage src={imageUrl} alt={name} width='50' height='50' />
				{isAdmin && (
					<AdminBadge>
						<i className='fas fa-shield-alt'></i>
					</AdminBadge>
				)}
			</ProfileWrapper>
			<div>
				<strong>{name}</strong> signed into {entry.app_name}
				<Date>{tidyRelativeDate(entry.date)}</Date>
			</div>
		</Component>
	);
};

export default Profile;
