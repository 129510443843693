import { tidyRelativeDate, tidyDateTime } from 'utils/Tidy';
import { OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import styled from 'styled-components';

const Component = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 0;
	border-top: 1px solid var(--border);

	&:last-of-type {
		padding-bottom: 0;
	}
`;

const AppName = styled.div`
	font-weight: bold;
	margin: 0 0 0.25rem;
`;

const Detail = styled.div`
	text-align: right;
	font-size: 0.8rem;
`;

const PermissionRow = ({ app, permissions, setPermissions }) => {
	// Destructure props
	const { name, id, isAdmin, lastSignIn } = app;

	// Handle permission toggle
	const handlePermissionToggle = e => {
		const { checked, name } = e.target;
		const newPermissions = permissions.map(permission => (permission.id === name ? { ...permission, isAdmin: checked } : permission));
		setPermissions(newPermissions);
	};

	return (
		<Component>
			<div>
				<AppName>{name}</AppName>
				<Form.Check type='switch' label='Admin' id={id} name={id} checked={isAdmin} onChange={e => handlePermissionToggle(e)} />
			</div>
			<Detail>
				<div>Last sign-in</div>
				<div className='text-muted'>
					{lastSignIn ? tidyRelativeDate(lastSignIn) : 'Unknown'}
					{lastSignIn && (
						<OverlayTrigger placement='top' overlay={<Tooltip className='secondary'>{tidyDateTime(lastSignIn)}</Tooltip>}>
							<i className='fas fa-info-circle ms-1'></i>
						</OverlayTrigger>
					)}
				</div>
			</Detail>
		</Component>
	);
};

export default PermissionRow;
