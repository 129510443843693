import styled from 'styled-components';

const Badge = styled.div`
	position: absolute;
	top: -0.25rem;
	font-size: 0.8rem;
	color: white;
	border-radius: 50%;
	width: 1.5rem;
	height: 1.5rem;
	display: grid;
	place-items: center;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
`;

const AdminBadgeComponent = styled(Badge)`
	background: var(--bs-danger);
	left: 2.5rem;
`;

const WebsiteBadgeComponent = styled(Badge)`
	background: var(--bs-primary);
	left: -0.5rem;
`;

export const AdminBadge = () => {
	return (
		<AdminBadgeComponent>
			<i className='fas fa-shield-alt'></i>
		</AdminBadgeComponent>
	);
};

export const WebsiteBadge = () => {
	return (
		<WebsiteBadgeComponent>
			<i className='fas fa-code'></i>
		</WebsiteBadgeComponent>
	);
};
