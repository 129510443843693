import dayjs from 'dayjs';
import { useLogin } from 'utils/UserContext';
import kiwi from 'img/kiwi.svg';
import styled from 'styled-components';

import ProfileImage from 'components/shared/ProfileImage';

const Component = styled.footer`
	color: var(--bs-secondary);
	background: var(--border);
	margin-top: auto;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.5rem;
	margin: auto auto 0;
	font-size: 0.8rem;
	padding: 1rem 1.5rem;

	@media (max-width: 767px) {
		flex-direction: column;
	}
`;

const Profile = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

const ProfileWrapper = styled.div`
	position: relative;
`;

const ProfileLink = styled.a`
	position: absolute;
	opacity: 0;
	transition: 0.5s;
	top: 0;
	left: 0;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 1rem;
	text-decoration: none;
`;

const Terms = styled.div`
	text-align: right;
`;

export default function Footer() {
	// User Hooks
	const { user } = useLogin();

	return (
		<Component>
			{user && (
				<Profile>
					<ProfileWrapper>
						<ProfileImage src={user.imageUrl} alt={user.email} height='40' width='40' />
						<ProfileLink href='https://myaccount.google.com/' target='_blank' rel='noopener noreferrer'>
							<i className='fas fa-external-link-alt'></i>
						</ProfileLink>
					</ProfileWrapper>
					<p className='m-0'>
						Signed in as: <span className='m-0 font-weight-bold'>{user.email}</span>
					</p>
				</Profile>
			)}
			<Terms>
				<p className='m-0'>© {dayjs().year()} AppFolio, All rights reserved.</p>
				<a className='text-muted text-decoration-none' target='_blank' rel='noopener noreferrer' href='https://www.appfolio.com/terms/websites'>
					Terms of Service
				</a>
				&nbsp;&nbsp;|&nbsp;&nbsp;
				<a className='text-muted text-decoration-none' target='_blank' rel='noopener noreferrer' href='https://www.appfolio.com/privacy'>
					Privacy Policy
				</a>
				&nbsp;&nbsp;
				<img src={kiwi} width='15' alt='Kiwi' />
			</Terms>
		</Component>
	);
}
